import { Action, Module, VuexModule } from 'vuex-class-modules'
import store from '@/store/index'
import {
  SupportedVehicleType,
  UserDetail,
  UserDetailDriver,
} from '@/models/dto'
import deepClone from '@/utils/deepClone'

const DRIVER_GROUP_ID = 4

@Module({ generateMutationSetters: true })
class UserDetailModule extends VuexModule {
  _currentUser: UserDetail | Record<string, never> = {}
  _currentUserAsDriver: UserDetailDriver | Record<string, never> = {}
  _treatAsDriver: boolean = false
  _groupId: number = null

  /**
   * Gets the current user's details.
   * @returns The user's details, or an empty object if no user is logged in.
   */
  get currentUser(): UserDetail | Record<string, never> {
    return this._currentUser
  }

  /**
   * Gets the current user's details as a driver.
   * @returns The user's driver details, or an empty object if no user is logged in or the user is not a driver.
   */
  get currentUserAsDriver(): UserDetailDriver | Record<string, never> {
    return this._currentUserAsDriver
  }

  /**
   * Gets the phone number of the current user (if they are a driver).
   * @returns The phone number of the current user, or an empty string if no user is logged in or the user is not a driver.
   */
  get phoneNumber(): string {
    return this._currentUserAsDriver?.phoneNumber || ''
  }

  /**
   * Gets a flag indicating whether the current user should be treated as a driver.
   * @returns `true` if the current user should be treated as a driver, `false` otherwise.
   */
  get treatAsDriver(): boolean {
    return this._treatAsDriver
  }

  /**
   * Gets the ID of the current user's group (if they are a driver).
   * @returns The ID of the current user's group, or `0` if no user is logged in or the user is not a driver.
   */
  get groupId(): number {
    return this._groupId
  }

  /**
   * Resets the state of the user manager, clearing the current user and driver details.
   */
  @Action
  reset(): void {
    this._currentUser = {}
    this._currentUserAsDriver = {}
    this._treatAsDriver = false
  }

  /**
   * Sets the current user's details.
   * @param currentUser - The user's details.
   */
  @Action
  setCurrentUser(currentUser: UserDetail | Record<string, never>): void {
    this._currentUser = currentUser
    this._currentUserAsDriver = Object.assign(
      {},
      this._currentUserAsDriver,
      currentUser
    )
    this.setGroupId(currentUser.groupId)
  }

  /**
   * Sets the email of the current user.
   * @param email - The email to set.
   */
  @Action
  setEmail(email: string): void {
    this._currentUser.email = email
    this._currentUserAsDriver.email = email
  }

  /**
   * Sets the first name of the current user.
   * @param firstName - The first name to set.
   */
  @Action
  setFirstName(firstName: string): void {
    this._currentUser.firstName = firstName
    this._currentUserAsDriver.firstName = firstName
  }

  /**
   * Sets the last name of the current user.
   * @param lastName - The last name to set.
   */
  @Action
  setLastName(lastName: string): void {
    this._currentUser.lastName = lastName
    this._currentUserAsDriver.lastName = lastName
  }

  /**
   * Sets the ID of the current user's group.
   * @param groupId - The ID of the group to set.
   */
  @Action
  setGroupId(groupId: number): void {
    this._currentUser.groupId = groupId
    this._currentUserAsDriver.groupId = groupId
    this._groupId = groupId
    if (groupId === DRIVER_GROUP_ID) {
      this.setTreatAsDriver(true)
    }
  }

  /**
   * Sets the role names of the current user.
   * @param userRoleNames - The role names to set.
   */
  @Action
  setUserRoleNames(userRoleNames: string[]): void {
    this._currentUser.userRoleNames = userRoleNames
    this._currentUserAsDriver.userRoleNames = userRoleNames
  }

  /**
   * Sets the current user's details as a driver.
   * @param currentUserAsDriver - The user's driver details.
   */
  @Action
  setCurrentUserAsDriver(
    currentUserAsDriver: UserDetailDriver | Record<string, never>
  ): void {
    this._currentUserAsDriver = currentUserAsDriver
    this._currentUser = Object.assign(
      {},
      this._currentUser,
      currentUserAsDriver
    )
  }

  /**
   * Sets the expiration month of the current user's drug test.
   * @param drugTestExpirationMonth - The expiration month to set.
   */
  @Action
  setDrugTestExpirationMonth(drugTestExpirationMonth: number): void {
    this._currentUserAsDriver.drugTestExpirationMonth = drugTestExpirationMonth
  }

  /**
   * Sets the expiration year of the current user's drug test.
   * @param drugTestExpirationYear - The expiration year to set.
   */
  @Action
  setDrugTestExpirationYear(drugTestExpirationYear: number): void {
    this._currentUserAsDriver.drugTestExpirationYear = drugTestExpirationYear
  }

  /**
   * Sets the expiration date of the current user's drug test.
   * @param drugTestExpiration - The expiration date to set (in the format "YYYY-MM-DD").
   */
  @Action
  setDrugTestExpiration(drugTestExpiration: string): void {
    this._currentUserAsDriver.drugTestExpiration = drugTestExpiration
  }

  /**
   * Sets the number of the current user's drug test.
   * @param drugTestNumber - The drug test number to set.
   */
  @Action
  setDrugTestNumber(drugTestNumber: string): void {
    this._currentUserAsDriver.drugTestNumber = drugTestNumber
  }

  /**
   * Sets the expiration year of the current user's driver's license.
   * @param licenseExpirationYear - The expiration year to set.
   */
  @Action
  setLicenseExpirationYear(licenseExpirationYear: number): void {
    this._currentUserAsDriver.licenseExpirationYear = licenseExpirationYear
  }

  /**
   * Sets the state of the current user's driver's license.
   * @param licensState - The state to set.
   */
  @Action
  setLicensState(licensState: string): void {
    this._currentUserAsDriver.licensState = licensState
  }

  /**
   * Sets the number of the current user's driver's license.
   * @param licenseNumber - The license number to set.
   */
  @Action
  setLicenseNumber(licenseNumber: string): void {
    this._currentUserAsDriver.licenseNumber = licenseNumber
  }

  /**
   * Sets the expiration month of the current user's driver's license.
   * @param licenseExpirationMonth - The expiration month to set.
   */
  @Action
  setLicenseExpirationMonth(licenseExpirationMonth: number): void {
    this._currentUserAsDriver.licenseExpirationMonth = licenseExpirationMonth
  }

  /**
   * Sets the SPAB certified status of the current user.
   * @param spabCertified - The value to set.
   */
  @Action
  setSpabCertified(spabCertified: boolean): void {
    this._currentUserAsDriver.spabCertified = spabCertified
  }

  /**
   * Sets the 19A certified status of the current user.
   * @param nineteenACertified - The value to set.
   */
  @Action
  setNineteenACertified(nineteenACertified: boolean): void {
    this._currentUserAsDriver.nineteenACertified = nineteenACertified
  }

  /**
   * Sets the supported vehicles of the current user (if they are a driver).
   * @param driverSupportedVehicles - The supported vehicles to set.
   */
  @Action
  setDriverSupportedVehicles(
    driverSupportedVehicles: SupportedVehicleType[]
  ): void {
    this._currentUserAsDriver.driverSupportedVehicles = driverSupportedVehicles
  }

  /**
   * Sets the phone number of the current user (if they are a driver).
   * @param phoneNumber - The phone number to set.
   */
  @Action
  setPhoneNumber(phoneNumber: string): void {
    this._currentUserAsDriver.phoneNumber = phoneNumber
  }

  /**
   * Sets the notes for the current user (if they are a driver).
   * @param notes - The notes to set.
   */
  @Action
  setNotes(notes: string): void {
    this._currentUserAsDriver.notes = notes
  }

  /**
   * Sets a flag indicating whether the current user should be treated as a driver.
   * @param treatAsDriver - `true` if the current user should be treated as a driver, `false` otherwise.
   */
  @Action
  setTreatAsDriver(treatAsDriver: boolean): void {
    this._treatAsDriver = treatAsDriver

    if (!this._currentUser.userRoleNames) {
      this.setUserRoleNames([])
    }

    const hasDriverRole = this._currentUser.userRoleNames.includes('is_driver')
    if (treatAsDriver && !hasDriverRole) {
      const userRoleNames = deepClone(this._currentUser.userRoleNames)
      userRoleNames.push('is_driver')
      this.setUserRoleNames(userRoleNames)
    } else if (!treatAsDriver && hasDriverRole) {
      let userRoleNames = deepClone(this._currentUser.userRoleNames)
      userRoleNames = userRoleNames.filter((r) => r !== 'is_driver')
      this.setUserRoleNames(userRoleNames)
    }
  }
}

export default new UserDetailModule({ store, name: 'userDetail' })
