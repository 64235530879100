import { bffProxyUrl } from '@/utils/env'
import Vue from 'vue'
import {
  ReservationTableViewResult,
  ReservationCommentPayload,
  ApiResult,
  ReferralRejectionRequest,
  MultiTripReferralResult,
  InterestIndicatedResult,
  CreateAssignmentPayload,
  CancellationTableViewResult,
  ReferralRejectionV2Request,
  ReservationDetail,
} from '@/models/dto'
import { CreateAssignmentApiResult } from '@/models/dto/CreateAssignmentApiResult'
import { AxiosResponse } from 'axios'
import { TableViewParameters } from '@/models/TableView'
import dayjs from 'dayjs'
import { GenericApiResult } from '../models/dto/GenericApiResult'
import {
  ReservationTrackingStatus,
  ReservationTrackingSummaryResponse,
} from '@/models/dto/Tracking'
import { getDaysAgo } from '@/utils/date'



export default {
  /**
   * Retrieves a paginated list of reservations.
   *
   * @param params - An object containing the request parameters, including sorting, filtering, pagination, and page size.
   * @returns a ReservationTableViewResult object.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<ReservationTableViewResult>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl
    const url = `${host}/tables/reservations?${query}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Retrieves a paginated list of reservation opportunities.
   *
   * @param params - An object containing the request parameters, including sorting, filtering, pagination, and page size.
   * @returns a ReservationTableViewResult object.
   */
  opportunities(
    params: TableViewParameters
  ): Promise<AxiosResponse<ReservationTableViewResult>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl
    const url = `${host}/reservations/opportunities?${query}`
    return Vue.prototype.$http.get(url)
  },
  /**
   *
   * Retrieves a reservation by its ID.
   *
   * @param id - The ID of the reservation to retrieve.
   * @returns the reservation detail object of the given ID.
   */
  byId(
    id: number
  ): Promise<AxiosResponse<GenericApiResult<ReservationDetail>>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/operator/${id}`
    return Vue.prototype.$http.get(url)
  },
  /**
   *
   * Retrieves the first accepted company referral assocaited to a given parent reservation ID.
   *
   * @param parentReservationId - The ID of the reservation to retrieve.
   * @returns the reservation detail object of the accepted referral with the given parent reservation ID and companyId = actorCompanyId.
   */
  byParentId(
    parentReservationId: number
  ): Promise<AxiosResponse<GenericApiResult<ReservationDetail>>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/operator/parentReservationId/${parentReservationId}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Retrieves a reservation by its unique hash.
   *
   * @param hash - The unique hash of the reservation to retrieve.
   * @returns the reservation detail object of the given hash.
   */
  byHash(
    hash: string
  ): Promise<AxiosResponse<GenericApiResult<ReservationDetail>>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/operator/hash/${hash}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Retrieves a list of reservations that are under the same multi-trip booking.
   *
   * @param id - The ID of an offered referral to find sibling referrals of.
   * @returns a list of reservation objects which are under the same quoteId.
   */
  multiBookingReferralsById(
    id: number
  ): Promise<AxiosResponse<MultiTripReferralResult>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/referralsWithMatchingQuoteId?referralId=${id}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Adds a comment to a reservation.
   *
   * @param payload - The comment to add and the reservation it should be added to.
   * @returns the ID of the added comment.
   */
  addComment(
    payload: ReservationCommentPayload
  ): Promise<AxiosResponse<number>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/${payload.reservation.id}/reservationComments`
    return Vue.prototype.$http.post(url, payload)
  },
  /**
   * Accepts a referral for a reservation.
   *
   * @param reservationId - The ID of the reservation to accept the referral for.
   * @param referralsReviewed - A boolean indicating whether or not the referrals have been reviewed.
   * @returns the accepted referrals.
   */
  accept(
    reservationId: number,
    referralsReviewed: boolean
  ): Promise<AxiosResponse<MultiTripReferralResult>> {
    const host = bffProxyUrl
    const query = `?referralsReviewed=${referralsReviewed}&referralAcceptanceESignature=`
    const url = `${host}/reservations/acceptReferral/${reservationId}${query}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Indicates interest in a reservation.
   *
   * @param reservationId - The ID of the reservation to indicate interest in.
   * @param interest - A boolean indicating whether or not the user is interested in the reservation.
   *
   * @returns an InterestIndicatedResult object.
   */
  interested(
    reservationId: number,
    interest: boolean
  ): Promise<AxiosResponse<InterestIndicatedResult>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/${reservationId}/interested?isInterested=${interest}`
    return Vue.prototype.$http.post(url, {})
  },
  /**
   * Rejects an opportunity to indicate interest on a reservation.
   *
   * @param reservationId - The ID of the reservation to reject.
   */
  rejectOpportunity(reservationId: number): Promise<AxiosResponse> {
    const host = bffProxyUrl
    const url = `${host}/reservations/${reservationId}/notInterested`
    return Vue.prototype.$http.patch(url, {})
  },
  /**
   * accepts an opportunity to indicate interest on a reservation.
   *
   * @param reservationId - The ID of the reservation to accept.
   */
  acceptAvailable(reservationId: number): Promise<AxiosResponse> {
    const host = bffProxyUrl
    const url = `${host}/reservations/${reservationId}/acceptAvailable`
    return Vue.prototype.$http.post(url, {})
  },
  /**
   * Accepts referrals en masse.
   *
   * @param reservationIds - An array of reservation IDs to accept.
   * @returns a standard API result.
   */
  acceptEnMasse(
    reservationIds: Array<number>
  ): Promise<AxiosResponse<ApiResult>> {
    const host = bffProxyUrl
    const payload = {
      reservationIds,
    }
    const url = `${host}/reservations/acceptReferralsEnMasse`
    return Vue.prototype.$http.post(url, payload)
  },
  /**
   * Rejects a referral
   *
   * @param reservationId - The ID of the reservation to reject.
   * @param body - An object containing the rejection reason type ID, whether referrals were
   * reviewed, and optional notes.
   * @returns a MultiTripReferralResult object in its data property.
   */
  reject(
    reservationId: number,
    body: ReferralRejectionRequest
  ): Promise<AxiosResponse<MultiTripReferralResult>> {
    const host = bffProxyUrl
    let query = `?rejectReasonTypeId=${body.referralRejectionReasonTypeId}&referralsReviewed=${body.referralsReviewed}`
    if (body?.notes) {
      query += `&notes=${body.notes}`
    }
    query = encodeURI(query)
    const url = `${host}/reservations/rejectReferral/${reservationId}${query}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Rejects a referral
   *
   * @param body - An object containing the reservation ID, the referral rejection reason classification ID, and whether referrals were
   * reviewed.
   * @returns a MultiTripReferralResult object in its data property.
   */
  rejectV2(
    body: ReferralRejectionV2Request
  ): Promise<AxiosResponse<MultiTripReferralResult>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/rejectReferral`
    return Vue.prototype.$http.post(url, body)
  },
  /**
   * Rejects referrals en masse.
   *
   * @param reservationIds - An array of reservation IDs to reject.
   * @returns a standard API result.
   */
  rejectEnMasse(
    reservationIds: Array<number>
  ): Promise<AxiosResponse<ApiResult>> {
    const host = bffProxyUrl
    const payload = {
      reservationIds,
    }
    const url = `${host}/reservations/rejectReferralsEnMasse`
    return Vue.prototype.$http.post(url, payload)
  },
  /**
   * Creates a driver/vehicle assignment for a given reservation.
   *
   * @param payload - The payload containing the reservation and the driver and vehicle assignment information
   * @returns a standard API result.
   */
  createAssignment(
    payload: CreateAssignmentPayload
  ): Promise<AxiosResponse<CreateAssignmentApiResult>> {
    const url = `${bffProxyUrl}/tripAssignments/createAssignment`
    return Vue.prototype.$http.post(url, payload)
  },
  /**
   * Sends driver info notification message for a given reservation.
   *
   * @param reservationId - The parent reservation id
   * @returns a standard API result.
   */
  sendDriverInfoNotifications(
    reservationId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/reservations/driverInfoNotifications/${reservationId}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Deletes a driver/vehicle assignment for a given reservation.
   *
   * @param ids - An array of assignment IDs to delete.
   * @returns a standard API result.
   */
  deleteAssignment(ids: number[]): Promise<AxiosResponse<ApiResult>> {
    const payload = {
      deleteAssignmentIds: ids,
    }

    return Vue.prototype.$http.delete(
      `${bffProxyUrl}/tripAssignments/deleteAssignments`,
      { data: payload }
    )
  },
  /**
   * Marks a referral as viewed by the operator.
   *
   * @param reservationId - The ID of the reservation.
   */
  operatorViewedReservation(
    reservationId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/operatorViewedReservation/${reservationId}?applicationId=3`
    return Vue.prototype.$http.get(url)
  },

  /**
   * Marks a referral as clicked request cancellation by the operator.
   *
   * @param reservationId - The ID of the reservation.
   */
  operatorClickedRequestCancellation(
    reservationId: number
  ): Promise<AxiosResponse<ApiResult>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/operatorClickedRequestCancellation/${reservationId}`
    return Vue.prototype.$http.get(url)
  },

  /**
   * Gets referral cancellation data for the company
   *
   * @param page - Page number for the table.
   * @param pageSize - Page size for the table.
   */
  operatorCancellationLast30Days(
    params: TableViewParameters
  ): Promise<AxiosResponse<CancellationTableViewResult>> {
    const page = params.page - 1
    const host = bffProxyUrl
    const today = dayjs().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
    const thirtyDaysAgo = getDaysAgo(30)
    const url = `${host}/reservations/cancellations?page=${page}&pageSize=${params.pageSize}&fromDate=${thirtyDaysAgo}&toDate=${today}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets referral cancellation data for the company
   *
   * @param page - Page number for the table.
   * @param pageSize - Page size for the table.
   */
  operatorCancellationLast90Days(
    params: TableViewParameters
  ): Promise<AxiosResponse<CancellationTableViewResult>> {
    const page = params.page - 1
    const host = bffProxyUrl
    const today = dayjs().utc().format('YYYY-MM-DD HH:mm:ss.SSS')
    const ninetyDaysAgo = getDaysAgo(90)
    const url = `${host}/reservations/cancellations?page=${page}&pageSize=${params.pageSize}&fromDate=${ninetyDaysAgo}&toDate=${today}`
    return Vue.prototype.$http.get(url)
  },

  /**
   * Gets tracking summaries for each vehicle/journey on each referral given
   * an array of referral ids
   *
   * @param referralIds - IDs of the referrals to get tracking summaries for.
   */
  getTrackingSummaries(
    referralIds: number[]
  ): Promise<AxiosResponse<ReservationTrackingSummaryResponse>> {
    const host = bffProxyUrl
    const url = `${host}/reservations/trackingSummary`
    return Vue.prototype.$http.post(url, referralIds)
  },
}
