import { bffProxyUrl } from '@/utils/env'
import { FeatureToggleGetResponse } from '@/models/dto/FeatureToggle'
import { AxiosResponse } from 'axios'
import Vue from 'vue'

export default {
  /**
   * Retrieves the active feature toggles for the current users company.
   * @returns A promise that resolves to an AxiosResponse with the feature toggles.
   */
  getFeatureToggles(): Promise<AxiosResponse<FeatureToggleGetResponse>> {
    return Vue.prototype.$http.get(`${bffProxyUrl}/featureToggle`)
  },
}
