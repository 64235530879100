import { bffProxyUrl } from '@/utils/env'
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { TableViewParameters, TableViewResult } from '@/models/TableView'
import {
  ApiResult,
  UserDetail,
  UserOnboardPayload,
  UserResult,
} from '@/models/dto'
import { RoleResult } from '@/models/dto/Role'

export default {
  /**
   * Gets the user table view.
   *
   * @param params - the parameters for the table view.
   * @returns the table view result of users.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<UserDetail>>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl

    return Vue.prototype.$http.get(`${host}/tables/users?${query}`)
  },
  /**
   * Gets a user by ID.
   *
   * @param userId - The ID of the user to fetch.
   * @returns the user result.
   */
  byId(userId: number): Promise<AxiosResponse<UserResult>> {
    return Vue.prototype.$http.get(`${bffProxyUrl}/user/${userId}`)
  },
  /**
   * Gets a user by hash.
   *
   * @param userHash - The hash of the user to fetch.
   * @returns the user result.
   */
  byHash(userHash: string): Promise<AxiosResponse<UserResult>> {
    return Vue.prototype.$http.post(`${bffProxyUrl}/user/getUserByHash`, {
      userHash,
    })
  },
  /**
   * Deletes a user by ID.
   *
   * @param userId - The ID of the user to delete.
   */
  delete(userId: number): Promise<AxiosResponse> {
    return Vue.prototype.$http.delete(`${bffProxyUrl}/v3/drivers/${userId}`)
  },
  /**
   * Gets the roles for a user.
   *
   * @param userId - The ID of the user to get roles for.
   * @returns the roles for the user.
   */
  roles(userId: number): Promise<AxiosResponse<RoleResult>> {
    const host = bffProxyUrl
    const url = `${host}/user/roles/${userId}`

    return Vue.prototype.$http.get(url)
  },
  /**
   * Sets the password for a user using a user hash.
   *
   * @param hash - The user_hash to use for setting the password.
   * @param password - The password to set.
   * @returns the user whose password was updated.
   */
  setPasswordWithHash(
    hash: string,
    password: string
  ): Promise<AxiosResponse<UserResult>> {
    const url = `${bffProxyUrl}/password/reset`
    return Vue.prototype.$http.post(url, { hash, password })
  },
  /**
   * Triggers the forgot password flow for the user with the given email.
   *
   * @param email - The email of the user to trigger the forgot password flow for.
   */
  forgotPassword(email: string): Promise<AxiosResponse> {
    return Vue.prototype.$http.post(`${bffProxyUrl}/user/forgotPassword/a3`, {
      email,
    })
  },
  /**
   * Validates a user hash.
   *
   * @param hash - The user hash to validate.
   * @returns the user whose hash was validated.
   */
  validateUser(hash: string): Promise<AxiosResponse<UserDetail>> {
    const url = `${bffProxyUrl}/user/validate`
    return Vue.prototype.$http.post(url, { userHash: hash })
  },
  /**
   * Creates a new user
   *
   * @param user - The user to create.
   * @returns the user that was created.
   */
  create(user: UserDetail): Promise<AxiosResponse<UserDetail>> {
    return Vue.prototype.$http.post(`${bffProxyUrl}/user`, user)
  },
  /**
   * Checks if a user exists with the given email.
   *
   * @param email - The email to check.
   * @returns true if the email exists, false otherwise.
   */
  checkIfEmailExists(
    email: string
  ): Promise<AxiosResponse<{ exists: boolean }>> {
    return Vue.prototype.$http.get(`${bffProxyUrl}/user/checkEmail/${email}`)
  },
  /**
   * Updates a user.
   * @param userId - The ID of the user to update.
   * @param user - The user information to update.
   */
  update(userId: number, user: UserDetail): Promise<AxiosResponse<string>> {
    return Vue.prototype.$http.patch(`${bffProxyUrl}/user/${userId}`, user)
  },
  /**
   * Onboards a user to CharterUP for Operators based on an invite.
   * @param payload - The payload to onboard the user with.
   * @returns a standard API result.
   */
  onboard(
    payload: UserOnboardPayload
  ): Promise<AxiosResponse<AxiosResponse<ApiResult>>> {
    const url = `${bffProxyUrl}/user/onboardCUPOPS`
    return Vue.prototype.$http.post(url, payload)
  },
  /**
   * Uploads a user photo.
   * @param userId - The ID of the user to upload the photo for.
   * @param photo - The photo to upload.
   * @returns a boolean indicating if the upload was successful.
   */
  uploadUserPhoto(
    userId: number,
    photo: FormData
  ): Promise<AxiosResponse<boolean>> {
    return Vue.prototype.$http.post(
      `${bffProxyUrl}/user/${userId}/uploadPhoto?primaryImage=true`,
      photo
    )
  },
}
