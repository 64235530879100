import { AxiosRequestConfig, AxiosResponse } from 'axios'
import { StatusCodes } from 'http-status-codes'
import { setRequestConfig } from '@/services/authTokenRepository'
import auth from '@/store/modules/auth'

export default function setup() {
  return {
    handleRequest,
    handleRequestError,
    handleSuccess,
    handleResponseError
  }
}

const handleRequest = async (
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> => {
  // Do something before request is sent
  // If request is different than any of the URLS in urlsExcludedForBearerHeader
  // then send Authorization header with token from localstorage
  return await setRequestConfig(config)
}

const handleRequestError = (error: any): Promise<any> => Promise.reject(error)

const handleSuccess = async (response: AxiosResponse): Promise<AxiosResponse> => {
  return response
}

const handleResponseError = async (error: any): Promise<any> => {
  if (error.response && error.response.status === 401) {
    if (error?.response?.status === StatusCodes.UNAUTHORIZED) {
      // We are going to log out users on 401 errors
      auth.logout()
    }
  }
  return Promise.reject(error)
}