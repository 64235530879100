import { bffProxyUrl } from '@/utils/env'
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { TableViewParameters } from '@/models/TableView'
import { SystemParameterTableViewResult } from '@/models/dto/SystemParameter'

export default {
  /**
   * Geta a table view of system parameters.
   *
   * @param params - An object containing query parameters for sorting, filtering, pagination,
   * and page size.
   * @returns a SystemParameterTableViewResult object.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<SystemParameterTableViewResult>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl
    const url = `${host}/tables/systemParameters?${query}`
    return Vue.prototype.$http.get(url)
  },
}
