import { bffProxyUrl } from '@/utils/env'
import Vue from 'vue'
import { ApiResult } from '@/models/dto/ApiResult'
import { AxiosResponse } from 'axios'
import {
  EldCredential,
  GetEldCredentialResponse,
  ELDValidationResponse,
  SamsaraCredentials,
  SauconCredentials,
  VerizonCredentials,
  GeotabCredentials,
} from '@/models/EldCredential'
import { GenericApiResult } from '@/models/dto/GenericApiResult'
import { EldTypeV2 } from '@/utils/enum'

export default {
  /**
   * Returns a list of all eld credentials
   *
   * @returns a list of all eld credentials
   */
  getAll(): Promise<AxiosResponse<GetEldCredentialResponse>> {
    const url = `${bffProxyUrl}/credentials`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Returns a list of all eld credentials including deactivated credentials that need review
   *
   * @returns a list of all eld credentials including deactivated credentials that need review
   */
  getAllByCompanyIdV2(
    companyId: number
  ): Promise<AxiosResponse<GenericApiResult<EldCredential[]>>> {
    const url = `${bffProxyUrl}/eldCredentials/${companyId}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Returns a list of all eld credentials including deactivated credentials that need review
   *
   * @returns a list of all eld credentials including deactivated credentials that need review
   */
  getAllByCompanyId(
    companyId: number
  ): Promise<AxiosResponse<GetEldCredentialResponse>> {
    const url = `${bffProxyUrl}/credentials/${companyId}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * create - Creates an EldCredential
   *
   * @param eldCredential - the EldCredential to create
   * @returns a general api result
   */
  create(eldCredential: EldCredential): Promise<AxiosResponse<ApiResult>> {
    if (eldCredential.eldType === EldTypeV2.Samsara) {
      return this.upsertSamsara(eldCredential)
    } else if (eldCredential.eldType === EldTypeV2.Geotab) {
      return this.upsertGeotab(eldCredential)
    } else if (eldCredential.eldType === EldTypeV2.VerizonConnect) {
      return this.upsertVerizon(eldCredential)
    } else if (eldCredential.eldType === EldTypeV2.Saucon) {
      return this.upsertSaucon(eldCredential)
    }
  },
  /**
   * create - Creates an EldCredential
   *
   * @param eldCredential - the EldCredential to create
   * @returns a general api result
   */
  upsertSamsara(
    eldCredential: EldCredential
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/eldCredentials/samsara`
    const samsaraPayload = {
      apiKey: eldCredential.accessToken,
      companyId: eldCredential.companyId,
    }
    return Vue.prototype.$http.post(url, samsaraPayload)
  },
  /**
   * create - Creates an EldCredential
   *
   * @param eldCredential - the EldCredential to create
   * @returns a general api result
   */
  upsertGeotab(
    eldCredential: EldCredential
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/eldCredentials/geotab`
    const geotabPayload = {
      password: eldCredential.accessToken,
      userName: eldCredential.userName,
      databaseName: eldCredential.database,
      companyId: eldCredential.companyId,
    }
    return Vue.prototype.$http.post(url, geotabPayload)
  },
  /**
   * create - Creates an EldCredential
   *
   * @param eldCredential - the EldCredential to create
   * @returns a general api result
   */
  upsertVerizon(
    eldCredential: EldCredential
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/eldCredentials/verizon`
    const verizonPayload = {
      password: eldCredential.accessToken,
      userName: eldCredential.userName,
      companyId: eldCredential.companyId,
    }
    return Vue.prototype.$http.post(url, verizonPayload)
  },
  /**
   * create - Creates an EldCredential
   *
   * @param eldCredential - the EldCredential to create
   * @returns a general api result
   */
  upsertSaucon(
    eldCredential: EldCredential
  ): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/eldCredentials/saucon`
    const sauconPayload = {
      apiKey: eldCredential.accessToken,
      companyId: eldCredential.companyId,
    }
    return Vue.prototype.$http.post(url, sauconPayload)
  },
  /**
   * Updates an EldCredential
   *
   * @param eldCredential - The EldCredential to update
   * @returns a general api result
   */
  update(eldCredential: EldCredential): Promise<AxiosResponse<ApiResult>> {
    const url = `${bffProxyUrl}/credentials`
    return Vue.prototype.$http.patch(url, eldCredential)
  },
  /**
   * Validates an EldCredential
   *
   * @param eldTypeId - The ID of the EldCredential to validate
   * @returns an object with a successful flag and a message
   */
  validate(eldTypeId: number): Promise<AxiosResponse<ELDValidationResponse>> {
    const url = `${bffProxyUrl}/credentials/validate/${eldTypeId}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Validates an EldCredential
   *
   * @param eldTypeId - The ID of the EldCredential to validate
   * @returns an object with a successful flag and a message
   */
  preValidate(
    eldTypeId: number,
    eldCredential: EldCredential
  ): Promise<AxiosResponse<ELDValidationResponse>> {
    const url = `${bffProxyUrl}/credentials/validate/${eldTypeId}`
    return Vue.prototype.$http.post(url, eldCredential)
  },
  /**
   * Validates Samsara credentials
   *
   * @returns an object with a successful flag and a message
   */
  preValidateSamsara(
    samsaraCredentials: SamsaraCredentials
  ): Promise<AxiosResponse<ELDValidationResponse>> {
    const url = `${bffProxyUrl}/eldCredentials/validate/samsara`
    return Vue.prototype.$http.post(url, samsaraCredentials)
  },
  /**
   * Validates Geotab credentials
   *
   * @returns an object with a successful flag and a message
   */
  preValidateGeotab(
    geotabCredentials: GeotabCredentials
  ): Promise<AxiosResponse<ELDValidationResponse>> {
    const url = `${bffProxyUrl}/eldCredentials/validate/geotab`
    return Vue.prototype.$http.post(url, geotabCredentials)
  },
  /**
   * Validates Verizon credentials
   *
   * @returns an object with a successful flag and a message
   */
  preValidateVerizon(
    verizonCredentials: VerizonCredentials
  ): Promise<AxiosResponse<ELDValidationResponse>> {
    const url = `${bffProxyUrl}/eldCredentials/validate/verizon`
    return Vue.prototype.$http.post(url, verizonCredentials)
  },
  /**
   * Validates Saucon credentials
   *
   * @returns an object with a successful flag and a message
   */
  preValidateSaucon(
    sauconCredentials: SauconCredentials
  ): Promise<AxiosResponse<ELDValidationResponse>> {
    const url = `${bffProxyUrl}/eldCredentials/validate/saucon`
    return Vue.prototype.$http.post(url, sauconCredentials)
  },
}
