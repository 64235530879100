import { VueConstructor } from 'vue'
import axios, { AxiosRequestConfig, AxiosResponse, AxiosInstance } from 'axios'

export default {
  install(Vue: VueConstructor, options) {
    const defaults = {
      headers: {
        ['Application-Origin']: 'cupops'
      }
    }

    const config = {
      ...defaults,
      ...options
    }

    const service: AxiosInstance = axios.create(config)

    service.interceptors.request.use(
      config.handleRequest,
      config.handleRequestError
    )
    service.interceptors.response.use(
      config.handleResponse,
      config.handleResponseError
    )

    Vue.prototype.$http = {
      get<T, P>(url: string, config?: AxiosRequestConfig): Promise<AxiosResponse> {
        return service.get<P, AxiosResponse<T>>(url, config)
      },
      put<T, P>(url: string, payload: P, config?: AxiosRequestConfig) {
        return service.put<P, AxiosResponse<T>>(url, payload, config)
      },
      patch<T, P>(url: string, payload: P, config?: AxiosRequestConfig){
        return service.patch<P, AxiosResponse<T>>(url, payload, config)
      },
      delete<T, P>(url: string, config?: AxiosRequestConfig) {
        return service.delete<P, AxiosResponse<T>>(url, config)
      },
      post<T, P>(url: string, payload: P, config?: AxiosRequestConfig){
        return service.post<P, AxiosResponse<T>>(url, payload, config)
      },
    }
  }
}