// Initialize Datadog before anything else
import { initDatadog } from './utils/datadog'

import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import '@/scss/main.scss'
import i18n from './i18n'
import '@/plugins/gmaps'
import '@/plugins/dayjs'
import '@/plugins/auth0'
import httpServicePlugin from '@/plugins/httpService'
import ga4 from '@/plugins/ga4'
import CUIcon from '@/components/CUIcon.vue'
import VueTheMask from 'vue-the-mask'
import CUTextField from '@/components/CUTextField.vue'
import CUSelect from '@/components/CUSelect.vue'
import CUModal from '@/components/CUModal.vue'
import CUModalV2 from '@/components/CUModalV2.vue'
import CUTextArea from '@/components/CUTextArea.vue'
import CUDataTable from '@/components/CUDataTable.vue'
import CUDatePicker from '@/components/CUDatePicker.vue'
import CUSkeletonLoaderTableView from '@/components/CUSkeletonLoaderTableView.vue'
import CUSkeletonLoader from '@/components/CUSkeletonLoader.vue'
import CUPasswordField from '@/components/CUPasswordField.vue'
import CUTimelineStep from '@/components/CUTimelineStep.vue'
import CUGauge from '@/components/CUGauge.vue'
import setup from '@/services/common/interceptors'

// before anything else, initialize datadog integration
initDatadog()

Vue.use(httpServicePlugin, {
  ...setup()
})
Vue.use(VueTheMask)
Vue.use(ga4)
Vue.component('CUIcon', CUIcon)
Vue.component('CUTextField', CUTextField)
Vue.component('CUTextArea', CUTextArea)
Vue.component('CUSelect', CUSelect)
Vue.component('CUModal', CUModal)
Vue.component('CUModalV2', CUModalV2)
Vue.component('CUDataTable', CUDataTable)
Vue.component('CUDatePicker', CUDatePicker)
Vue.component('CUSkeletonLoaderTableView', CUSkeletonLoaderTableView)
Vue.component('CUSkeletonLoader', CUSkeletonLoader)
Vue.component('CUPasswordField', CUPasswordField)
Vue.component('CUTimelineStep', CUTimelineStep)
Vue.component('CUGauge', CUGauge)
Vue.config.productionTip = false

async function init() {
  // Ensure Auth0 client is fully initialized before validating user
  // or starting router
  await Vue.prototype.$auth0.init()

  new Vue({
    router,
    store,
    vuetify,
    i18n,
    render: (h) => h(App),
  }).$mount('#app')
}

init()
