import { bffProxyUrl } from '@/utils/env'
import { Trip, TripTableViewResult } from '@/models/dto'
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { TableViewParameters } from '@/models/TableView'

export default {
  /**
   * Gets the trip table view.
   *
   * @param params - The parameters for the table view.
   * @param quoteIdList - The list of quote IDs to filter by.
   * @returns the trip table view result.
   */
  tableView(
    params: TableViewParameters,
    quoteIdList: string | null = null
  ): Promise<AxiosResponse<TripTableViewResult>> {
    const { sorts = null, filters = null, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    if (quoteIdList) {
      query = `${query}&quoteId=${quoteIdList}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl
    const url = `${host}/tables/trips?${query}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets a trip by ID.
   * @param id - The ID of the trip to fetch.
   * @returns the trip result.
   */
  byId(id: number): Promise<AxiosResponse<Trip>> {
    const host = bffProxyUrl
    const url = `${host}/trips/${id}/operator`
    return Vue.prototype.$http.get(url)
  },
}
