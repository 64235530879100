import { bffProxyUrl } from '@/utils/env'
import Vue from 'vue'
import { AxiosResponse } from 'axios'
import { BidPayload, BidTableViewResult } from '@/models/dto/Bid'
import { TableViewParameters } from '@/models/TableView'

export default {
  /**
   * Retrieves the bid data for a table view with the given parameters.
   *
   * @param params - An object containing the request parameters, including sorting, filtering, pagination, and page size.
   * @returns A promise that resolves to the bid data for the table view.
   */
  tableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<BidTableViewResult>> {
    const { sorts, filters, pageSize = 10, page = 1 } = params

    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)
    const host = bffProxyUrl
    const url = `${host}/tables/bids?${query}`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Creates a new bid with the given information.
   *
   * @param payload - An object containing the information for the new bid.
   * @returns A promise that resolves to an AxiosResponse with the ID of the newly created bid.
   */
  create(payload: BidPayload): Promise<AxiosResponse<number>> {
    const host = bffProxyUrl
    const url = `${host}/bids`
    return Vue.prototype.$http.post(url, payload)
  },
  /**
   * Updates an existing bid with the given information.
   *
   * @param bidId - The ID of the bid to update.
   * @param payload - An object containing the updated information for the bid.
   * @returns A promise that resolves to an AxiosResponse with the ID of the updated bid.
   */
  update(bidId: number, payload: BidPayload): Promise<AxiosResponse<number>> {
    const host = bffProxyUrl
    const url = `${host}/bids/${bidId}`
    return Vue.prototype.$http.patch(url, payload)
  },
}
