import { TableViewParameters, TableViewResult } from '@/models/TableView'
import Vue from 'vue'
import { AmenityType } from '@/models/dto/Amenity'
import { AvailabilityBlockReasonTypeGetResponse } from '@/models/dto/Availability'
import { AxiosResponse } from 'axios'
import { EldType } from '@/models/EldCredential'
import { ReferralRejectionReasonType } from '@/models/dto/ReferralRejectionReasonType'
import { VehicleType } from '@/models/dto'
import { bffProxyUrl } from '@/utils/env'
import { MarkupType } from '@/models/dto/MarkupType'
import { ClassificationType } from '@/models/Classification'

export default {
  /**
   * Gets the vehicle type table view.
   *
   * @param params - the parameters for the table view.
   * @returns the table view result of vehicle types.
   */
  vehicleTypeTableView(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<VehicleType>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const host = bffProxyUrl
    const url = `${host}/tables/vehicleTypes?${query}`

    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets availability block reason types.
   *
   * @param params - the parameters for the table view.
   * @returns the table view result of amenity types.
   */
  availabilityBlockReasonType(): Promise<
    AxiosResponse<AvailabilityBlockReasonTypeGetResponse>
  > {
    const host = bffProxyUrl
    const url = `${host}/availabilityBlocks/getReasonTypes`

    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets the amenity type table view.
   *
   * @param {TableViewParameters} params - The parameters for the table view.
   * @returns the table view result of vehicle types.
   */
  amenity(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<AmenityType>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query += `&${sorts}`
    }
    if (filters) {
      query += `&${filters}`
    }
    query = encodeURI(query)

    const host = bffProxyUrl
    const url = `${host}/tables/amenityTypes?${query}`

    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets the referral rejection reason types.
   *
   * @returns the referral rejection reason types.
   */
  referralRejectionReason(): Promise<
    AxiosResponse<ReferralRejectionReasonType>
  > {
    const host = bffProxyUrl
    const url = `${host}/types/referral-rejection-reason-types`
    return Vue.prototype.$http.get(url)
  },

  /**
   * Gets the market rate types.
   *
   * @returns the market rate types.
   */
  marketRateType(): Promise<AxiosResponse> {
    const host = bffProxyUrl
    const url = `${host}/types/market-rate-types`

    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets the eld type table view.
   * @param params - the parameters for the table view.
   * @returns the table view result of eld types.
   */
  eld(
    params: TableViewParameters
  ): Promise<AxiosResponse<TableViewResult<EldType>>> {
    const { pageSize = 10, page = 1, sorts = null, filters = null } = params
    let query = `page=${page}&pageSize=${pageSize}`
    if (sorts) {
      query = `${query}&${sorts}`
    }
    if (filters) {
      query = `${query}&${filters}`
    }
    query = encodeURI(query)

    const host = bffProxyUrl
    const url = `${host}/tables/eldTypes?${query}`

    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets the markup types.
   * @returns the markup types.
   */
  markup(): Promise<AxiosResponse<TableViewResult<MarkupType>>> {
    const host = bffProxyUrl
    const url = `${host}/tables/markup-types`
    return Vue.prototype.$http.get(url)
  },
  /**
   * Gets the classification types.
   * @returns the classification types.
   */
  classification(): Promise<AxiosResponse<ClassificationType[]>> {
    const host = bffProxyUrl
    const url = `${host}/types/classification-type`
    return Vue.prototype.$http.get(url)
  },
}
