import Vue from 'vue'
import { AxiosRequestConfig } from 'axios'
import jwtDecode from 'jwt-decode'
import { load, save } from '@/utils/localStorage'
import { hoursToSeconds, secondsFromNow } from '@/utils/time'

type Token = {
  provider: string
  value: string
}

enum TokenProvider {
  Auth0 = 'auth0',
  Legacy = 'legacy',
}

/**
 * Adds a bearer token to the authorization header for outgoing requests.
 *
 * @returns {Promise<boolean>} - A promise that returns an Axios request configuration object.
 */
export async function setRequestConfig(
  config: AxiosRequestConfig
): Promise<AxiosRequestConfig> {
  const token = await getCurrentAuthToken()
  // If there is an existing token, add it to the request headers
  if (token) {
    config.headers.Authorization = `Bearer ${token}`
  }
  return config
}

/**
 * Stores the token in local storage. If it's from a legacy provider, it will be stored differently.
 */
export function saveAuthToken(token: string, legacy: boolean = false): void {
  const newToken: Token = {
    provider: legacy ? TokenProvider.Legacy : TokenProvider.Auth0,
    value: token,
  }
  save('token', newToken)
}

/**
 * Retrieves the current authorization token from local storage.
 * Legacy tokens are given priority if they exist, otherwise the Auth0 token is used.
 * Auth0 tokens that are expiring within an hour will be refreshed.
 *
 * @returns {Promise<string | null>} - A promise that returns the current token or null if no token was found.
 */
async function getCurrentAuthToken(): Promise<string | null> {
  const token = load('token')

  if (!token) {
    return null
  }

  if (typeof token === 'string') {
    return token
  }

  const tokenValue = token.value
  // If we have a legacy token, still return it
  if (token.provider === TokenProvider.Legacy) {
    return tokenValue
  }

  const decoded: any = jwtDecode(tokenValue)
  // If we've assigned a token that's expiring in an hour, refresh it
  const expiresInHour = secondsFromNow(decoded?.exp) < hoursToSeconds(1)
  if (!expiresInHour) {
    return tokenValue
  }

  const didTokenRefresh = await refreshAuthToken()
  if (!didTokenRefresh) {
    return tokenValue
  }

  const newToken = load('token')
  return newToken.value
}

/**
 * Attempts to refresh the current access token and save it to local storage using the session's refresh token.
 * https://auth0.com/docs/authenticate/login/configure-silent-authentication
 *
 * @returns {Promise<boolean>} - A promise that resolves to `true` if the refresh succeeds,
 * or `false` if the refresh is unsuccessful.
 */
async function refreshAuthToken(): Promise<boolean> {
  try {
    if (!Vue.prototype.$auth0) {
      console.error('Unable to refresh token, auth0 client not initialized')
    }
    const refreshed = await Vue.prototype.$auth0.getTokenSilently()
    saveAuthToken(refreshed)
    return true
  } catch (error) {
    // Something went wrong with refreshing, likely the refresh token expired
    return false
  }
}
